const ReinsHeaderList = [
    "物件番号",
    "データ種類",
    "物件種別",
    "物件種目",
    "会員名",
    "代表電話番号",
    "問合せ担当者（1）",
    "問合せ電話番号（1）",
    "Eメールアドレス（1）",
    "図面",
    "登録年月日",
    "変更年月日",
    "取引条件の有効期限",
    "特優賃区分",
    "都道府県名",
    "所在地名1",
    "所在地名2",
    "所在地名3",
    "建物名",
    "部屋番号",
    "その他所在地表示",
    "棟番号",
    "沿線略称（1）",
    "駅名（1）",
    "徒歩（分）1（1）",
    "徒歩（m）2（1）",
    "バス（1）",
    "バス路線名（1）",
    "バス停名称（1）",
    "停歩（分）（1）",
    "停歩（m）（1）",
    "車（km）（1）",
    "その他交通手段",
    "交通（分）1",
    "交通（m）2",
    "現況",
    "現況予定年月",
    "入居時期",
    "入居年月（西暦）",
    "入居旬",
    "取引態様",
    "報酬形態",
    "負担割合貸主",
    "負担割合借主",
    "配分割合元付",
    "配分割合客付",
    "報酬ヶ月",
    "報酬額",
    "賃料",
    "賃料消費税",
    "坪単価",
    "㎡単価",
    "保証金1（額）",
    "保証金2（ヶ月）",
    "権利金1（額）",
    "権利金消費税（額）",
    "権利金2（ヶ月）",
    "礼金1（額）",
    "礼金消費税（額）",
    "礼金2（ヶ月）",
    "敷金1（額）",
    "敷金2（ヶ月）",
    "償却コード",
    "償却総額",
    "償却月数",
    "償却率",
    "契約期間",
    "契約期限（西暦）",
    "解約引総額",
    "解約引月数",
    "解約引率",
    "土地面積",
    "面積計測方式",
    "建物面積1",
    "使用部分面積",
    "バルコニー（テラス）面積",
    "専用庭面積",
    "施設費用項目（1）",
    "施設費用（1）",
    "用途地域（1）",
    "用途地域（2）",
    "登記簿地目",
    "現況地目",
    "都市計画",
    "建ぺい率",
    "容積率",
    "地域地区",
    "最適用途",
    "地勢",
    "建物賃貸借区分",
    "建物賃貸借期間",
    "建物賃貸借更新",
    "管理費",
    "管理組合有無",
    "管理費消費税",
    "共益費",
    "共益費消費税",
    "雑費",
    "雑費消費税",
    "その他月額費名称1",
    "その他月額費用金額1",
    "更新区分",
    "更新料（額）",
    "更新料（ヶ月）",
    "保険加入義務",
    "保険名称",
    "保険料",
    "保険期間",
    "接道状況",
    "接道種別1",
    "接道接面1",
    "接道位置指定1",
    "接道方向1",
    "接道幅員1",
    "接道種別2",
    "接道接面2",
    "接道位置指定2",
    "接道方向2",
    "接道幅員2",
    "接道種別3",
    "接道接面3",
    "接道位置指定3",
    "接道方向3",
    "接道幅員3",
    "接道種別4",
    "接道接面4",
    "接道位置指定4",
    "接道方向4",
    "接道幅員4",
    "接道舗装",
    "間取タイプ（1）",
    "間取部屋数（1）",
    "部屋位置",
    "納戸数",
    "室所在階1（1）",
    "室タイプ1（1）",
    "室広さ1（1）",
    "室数1（1）",
    "室所在階2（1）",
    "室タイプ2（1）",
    "室広さ2（1）",
    "室数2（1）",
    "室所在階3（1）",
    "室タイプ3（1）",
    "室広さ3（1）",
    "室数3（1）",
    "室所在階4（1）",
    "室タイプ4（1）",
    "室広さ4（1）",
    "室数4（1）",
    "室所在階5（1）",
    "室タイプ5（1）",
    "室広さ5（1）",
    "室数5（1）",
    "室所在階6（1）",
    "室タイプ6（1）",
    "室広さ6（1）",
    "室数6（1）",
    "室所在階7（1）",
    "室タイプ7（1）",
    "室広さ7（1）",
    "室数7（1）",
    "間取りその他（1）",
    "駐車場在否",
    "駐車場月額",
    "駐車場月額消費税",
    "駐車場敷金（額）",
    "駐車場敷金（ヶ月）",
    "駐車場礼金（額）",
    "駐車場礼金（ヶ月）",
    "建物構造",
    "建物工法",
    "建物形式",
    "地上階層",
    "地下階層",
    "所在階",
    "築年月（西暦）",
    "新築フラグ",
    "バルコニー方向（1）",
    "増改築年月1",
    "増改築履歴1",
    "増改築年月2",
    "増改築履歴2",
    "増改築年月3",
    "増改築履歴3",
    "周辺環境1（フリー）",
    "距離1",
    "時間1",
    "周辺アクセス１",
    "備考1",
    "備考2",
    "[賃貸]賃貸戸数",
    "[賃貸]棟総戸数",
    "[賃貸]連棟戸数",
    "自社管理欄"
]

export default ReinsHeaderList;