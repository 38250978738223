import React, { useState, useEffect } from "react";

const MaisokuDisplay = ({ imageUrl, boundingBox, fillColor = "red", opacity = 0.5  }) => {
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const [paddingTop, setPaddingTop] = useState("100%");
    useEffect(() => {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
            setImageSize({ width: img.width, height: img.height });
            const aspectRatio = (img.height / img.width) * 100;
            setPaddingTop(`${aspectRatio}%`);
        };
    }, [imageUrl]);

    const scaledBoundingBox = boundingBox
    ? {
        left: (boundingBox.left / imageSize.width) * 100,
        top: (boundingBox.top / imageSize.height) * 100,
        width: ((boundingBox.right - boundingBox.left) / imageSize.width) * 100,
        height: ((boundingBox.bottom - boundingBox.top) / imageSize.height) * 100,
    }
    : null;

    return (
        <div style={{ position: "relative", width: "100%", paddingTop: paddingTop, overflow: "hidden" }}>
            <img
                src={imageUrl}
                alt="Sample"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                }}
            />
            {scaledBoundingBox && (
                <div
                style={{
                    position: "absolute",
                    border: "3px solid red",
                    backgroundColor: fillColor,
                    opacity: opacity,
                    left: `${scaledBoundingBox.left}%`,
                    top: `${scaledBoundingBox.top}%`,
                    width: `${scaledBoundingBox.width}%`,
                    height: `${scaledBoundingBox.height}%`,
                }}
                ></div>
            )}
        </div>
    );
};

export default MaisokuDisplay;
