import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bulma/css/bulma.css";
import { Authenticator, Text, View, useTheme } from '@aws-amplify/ui-react'


import { Amplify, Auth } from 'aws-amplify';
import awsconfig from './aws-exports';

const redirectUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;


Amplify.configure({
    ...awsconfig,
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: "ap-northeast-1",
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        // ここから
        cookieStorage: {
            // 環境変数の名前は適宜変更する
            domain: `.${process.env.REACT_APP_CUSTOM_DOMAIN}`,
        },
        // ここまで
        oauth: {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            scope: [
                "phone",
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: redirectUrl,
            redirectSignOut: redirectUrl,
            responseType: "code",
        },
    },
    API: {
        endpoints: [
            {
                name: 'stg-bukken-scan-db-controller-read',
                endpoint: process.env.REACT_APP_READ_DYNAMODB_ENDPOINT,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                    };
                },
            },
            {
                name: 'stg-bukken-scan-db-controller-update',
                endpoint: process.env.REACT_APP_UPDATE_DYNAMODB_ENDPOINT,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                    };
                },
            },
            {
                name: 'stg-bukken-scan-db-controller-delete',
                endpoint: process.env.REACT_APP_DELETE_DYNAMODB_ENDPOINT,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                    };
                },
            },
            {
                name: 'stg-bukken-scan-executor',
                endpoint: process.env.REACT_APP_EXECUTE_ENDPOINT,
                custom_header: async () => {
                    return {
                        Authorization: `Bearer ${(await Auth.currentSession())
                        .getAccessToken()
                        .getJwtToken()}`,
                    };
                },
            },
        ],
    },
    "input_bucket": process.env.REACT_APP_INPUT_BUCKET_NAME,
    "input_bucket_region": 'ap-northeast-1',
    "output_bucket": process.env.REACT_APP_OUTPUT_BUCKET_NAME,
    "output_bucket_region": 'ap-northeast-1',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Authenticator
            socialProviders={['google']}
            hideSignUp={true}
            components={{
                Footer() {
                    const { tokens } = useTheme();
                    return (
                        <View textAlign="center" padding={tokens.space.large}>
                            <Text color={tokens.colors.neutral[80]}>
                                ログインすると、
                                <a
                                    href={`https://${
                                        process.env.REACT_APP_CUSTOM_DOMAIN
                                    }/term_of_service.pdf`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                利用規約
                                </a>
                                に同意したものとします
                            </Text>
                            <Text color={tokens.colors.neutral[80]}>
                                &copy; GA technologies AI Strategy Center. All Rights Reserved
                            </Text>
                        </View>
                    );
                },
                SignIn: {},
            }}>
            <App />
        </Authenticator>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
