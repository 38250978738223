import React, { useState, useEffect } from 'react';
import { Amplify, Auth, Storage, API } from 'aws-amplify';
import MaisokuDisplay from "./MaisokuDisplay";
import DetectedImageDisplay from "./DetectedImageDisplay";

import awsconfig from "../aws-exports";
const ModalDetails = ({ data, closeModal, triggerReRender }) => {

    const tabs = [
        { id: 'basic', name: '基本' },
        { id: 'building', name: '建物' },
        { id: 'land', name: '土地' },
        { id: 'transaction', name: '取引' },
        { id: 'transportation', name: '交通' },
        { id: 'image', name: '画像' },
    ];

    const [activeTab, setActiveTab] = React.useState(tabs[0].id);
    const [originalData] = useState(data);
    const [editableData, setEditableData] = useState(data);
    const [maisokuUrls, setMaisokuUrls] = useState({ main: "", blueprint: "" });
    const [boundingBox, setBoundingBox] = useState(null);
    const [responseState, setResponseState] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (data && data.jpg_key) {
            fetchMaisoku(data.jpg_key);
        }
    }, [data]);

    const fetchMaisoku = async (filename) => {
        try {
            const fileUrl = await Storage.get(filename, {
                bucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
                level: "private",
                expires: 86400,
            });
            let bluePrintKey = `${filename.split('.jpg')[0]}_base.png`;
            const detectImageFileUrl = await Storage.get(bluePrintKey, {
                bucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
                level: "private",
                expires: 86400,
            });

            setMaisokuUrls({ main: fileUrl, blueprint: detectImageFileUrl });
        } catch (error) {
            console.error("Error fetching maisoku:", error);
        }
    };

    const handleChange = (e, field, nestedField = null) => {
        const newData = { ...editableData };
        if (nestedField) {
            newData[field] = { ...newData[field], [nestedField]: e.target.value };
        } else {
            newData[field] = e.target.value;
        }
        setEditableData(newData);
    };

    const updateData = async () => {
        let updatedData = { created_at: data.created_at, id_token: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        for (let key in editableData) {
            if (editableData[key] !== originalData[key]) {
                updatedData[key] = editableData[key];
            }
        }

        postData(updatedData, 'stg-bukken-scan-db-controller-update', "Update success:");
    };

    const deleteData = async () => {
        let deleteData = { created_at: data.created_at, id_token: `${(await Auth.currentSession()).getIdToken().getJwtToken()}` };
        if (window.confirm("本当に削除しますか？")) {
            await postData(deleteData, 'stg-bukken-scan-db-controller-delete', "DELETE success:");
            closeModal();
            triggerReRender();
        }
    };

    const postData = async (data, apiName, successMessage) => {
        setIsUpdating(true);
        try {
            const responseJson = await API.post(apiName, '/', {
                body: data
            });
            setResponseState(responseJson);
            alert("更新が完了しました！");
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setIsUpdating(false);
        }
    };


    const handleMouseOver = (key) => {
        if (editableData && editableData[`${key}_pos`]) {
            setBoundingBox(editableData[`${key}_pos`]);
        }
    };

    const handleMouseLeave = () => {
        setBoundingBox(null);
    };

    // responseStateが変更されたら再レンダリングする
    useEffect(() => {

    }, [responseState]);

    const renderTabContent = () => {
        switch (activeTab) {
            case 'basic':
                return (
                    <div>
                        <div className="field">
                            <label className="label">物件名:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.building_name}
                                onMouseOver={() => handleMouseOver('building_name')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'building_name')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">都道府県:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.prefecture}
                                onMouseOver={() => handleMouseOver('address')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'prefecture')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">所在地名1:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.municipalities}
                                onMouseOver={() => handleMouseOver('address')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'municipalities')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">所在地名2:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.city}
                                onMouseOver={() => handleMouseOver('address')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'city')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">所在地名3:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.street}
                                onMouseOver={() => handleMouseOver('address')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'street')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">価格:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.intermediary_preferred_price}
                                onMouseOver={() => handleMouseOver('intermediary_preferred_price')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'intermediary_preferred_price')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">賃料:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.rental_price}
                                onMouseOver={() => handleMouseOver('rental_price')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'rental_price')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">管理費:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.administrative_cost}
                                onMouseOver={() => handleMouseOver('administrative_cost')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'administrative_cost')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">共益費:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.common_charges}
                                onMouseOver={() => handleMouseOver('common_charges')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'common_charges')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">積立金:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.repair_reserve_fund}
                                onMouseOver={() => handleMouseOver('repair_reserve_fund')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'repair_reserve_fund')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">敷金:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.security_deposit}
                                onMouseOver={() => handleMouseOver('security_deposit')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'security_deposit')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">礼金:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.gratuity_fee}
                                onMouseOver={() => handleMouseOver('gratuity_fee')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'gratuity_fee')}
                            />
                            </div>
                        </div>
                    </div>
                );
            case 'building':
                return (
                    <div>
                        <div className="field">
                            <label className="label">建築年:</label>
                            <div className="control" style={{ display: "flex" , alignItems: "center"}}>
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.built_year}
                                    onMouseOver={() => handleMouseOver('built_year')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'built_year')}
                                />
                                <span style={{ lineHeight: "inherit", fontSize: "inherit", marginLeft: "4px", marginRight: "4px" }}>年</span>
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.built_month}
                                    onMouseOver={() => handleMouseOver('built_year')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'built_month')}
                                />
                                <span style={{ lineHeight: "inherit", fontSize: "inherit", marginLeft: "4px", marginRight: "4px" }}>月</span>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">構造:</label>
                            <div className="control">
                                <div className="select">
                                    <select
                                    value={editableData.building_structure}
                                    onMouseOver={() => handleMouseOver('building_structure')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'building_structure')}
                                    >
                                    <option value="01">木造</option>
                                    <option value="02">ブロック増</option>
                                    <option value="03">鉄骨造</option>
                                    <option value="04">RC (鉄筋コンクリート造)</option>
                                    <option value="05">SRC (鉄骨鉄筋コンクリート造)</option>
                                    <option value="06">PC (プレキャストコンクリート造)</option>
                                    <option value="07">HPC (鉄骨プレキャストコンクリート造)</option>
                                    <option value="08">軽量鉄骨</option>
                                    <option value="101">CFT構造</option>
                                    <option value="09">その他</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="control" style={{ display: "flex" }}>
                            <div className="field">
                                <label className="label">建物階数:</label>
                                <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.stories_high}
                                    onMouseOver={() => handleMouseOver('stories_high')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'stories_high')}
                                />
                                </div>
                            </div>
                            <div className="field ml-4">
                                <label className="label">所在階:</label>
                                <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.floor}
                                    onMouseOver={() => handleMouseOver('floor')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'floor')}
                                />
                                </div>
                            </div>
                            <div className="field ml-4">
                                <label className="label">総戸数:</label>
                                <div className="control">
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.total_units}
                                    onMouseOver={() => handleMouseOver('total_units')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'total_units')}
                                />
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">間取り:</label>
                            <div className="control" style={{ display: "flex", alignItems: "center" }}> {/* ここを追加 */}
                                <input
                                    className="input"
                                    type="text"
                                    value={editableData.total_rooms}
                                    onMouseOver={() => handleMouseOver('total_rooms')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'total_rooms')}
                                />
                                <input
                                    className="input ml-4"
                                    type="text"
                                    value={editableData.room_layout}
                                    onMouseOver={() => handleMouseOver('room_layout')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'room_layout')}
                                />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">平米数:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.total_footprint}
                                onMouseOver={() => handleMouseOver('total_footprint_house')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'total_footprint')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">バルコニー面積:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.balcony_area}
                                onMouseOver={() => handleMouseOver('balcony_area')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'balcony_area')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">委託タイプ:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.management_type}
                                onMouseOver={() => handleMouseOver('management_type')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'management_type')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">管理人タイプ:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.manager_type}
                                onMouseOver={() => handleMouseOver('manager_type')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'manager_type')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">分譲会社:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.condo_company}
                                onMouseOver={() => handleMouseOver('condo_company')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'condo_company')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">施工会社:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.construction_company}
                                onMouseOver={() => handleMouseOver('construction_company')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'construction_company')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">管理会社:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.management_company}
                                onMouseOver={() => handleMouseOver('management_company')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'management_company')}
                            />
                            </div>
                        </div>
                    </div>

                );
            case 'land':
                return (
                    <div>
                        <div className="field">
                            <label className="label">権利:</label>
                            <div className="control">
                                <div className="select">
                                    <select
                                        value={editableData.land_rights}
                                        onMouseOver={() => handleMouseOver('land_rights')}
                                        onMouseLeave={handleMouseLeave}
                                        onChange={(e) => handleChange(e, 'land_rights')}
                                    >
                                    <option value="1">所有権</option>
                                    <option value="2">旧法地上</option>
                                    <option value="3">旧法賃借</option>
                                    <option value="4">普通地上</option>
                                    <option value="5">定期地上</option>
                                    <option value="6">普通賃借</option>
                                    <option value="7">定期賃借</option>
                                    <option value="8">借地権</option>
                                    <option value="0">不明</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">高度地区:</label>
                            <div className="control">
                                <div className="select">
                                    <select
                                        value={editableData.height_control_district}
                                        onMouseOver={() => handleMouseOver('height_control_district')}
                                        onMouseLeave={handleMouseLeave}
                                        onChange={(e) => handleChange(e, 'height_control_district')}
                                    >
                                        <option value="第一種高度地区">第一種高度地区</option>
                                        <option value="第二種高度地区">第二種高度地区</option>
                                        <option value="第三種高度地区">第三種高度地区</option>
                                        <option value="第四種高度地区">第四種高度地区</option>
                                        <option value="">不明</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">防災地域:</label>
                            <div className="control">
                                <div className="select">
                                    <select
                                        value={editableData.fire_control_area}
                                        onMouseOver={() => handleMouseOver('fire_control_area')}
                                        onMouseLeave={handleMouseLeave}
                                        onChange={(e) => handleChange(e, 'fire_control_area')}
                                    >
                                        <option value="22条区域">22条区域</option>
                                        <option value="準防火地域">準防火地域</option>
                                        <option value="防火地域">防火地域</option>
                                        <option value="指定なし">指定なし</option>
                                        <option value="">不明</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">市街化区域:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.urbanization_promotion_area}
                                onMouseOver={() => handleMouseOver('urbanization_promotion_area')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'urbanization_promotion_area')}
                            />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">土地面積:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.land_area}
                                onMouseOver={() => handleMouseOver('land_area')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'land_area')}
                            />
                            </div>
                        </div>
                    </div>
                );
            case 'transaction':
                return (
                    <div>
                        <div className="field">
                            <label className="label">AD:</label>
                            <div className="control">
                            <input
                                className="input"
                                type="text"
                                value={editableData.advertisement_fee}
                                onMouseOver={() => handleMouseOver('advertisement_fee')}
                                onMouseLeave={handleMouseLeave}
                                onChange={(e) => handleChange(e, 'advertisement_fee')}
                            />
                            </div>
                        </div>
                    </div>
                );
            case 'transportation':
                return (
                    <div>
                        {['station_0', 'station_1', 'station_2'].map((stationKey, index) => {
                            const station = editableData[stationKey];
                            const line = station ? station.line : '';
                            const stationName = station ? station.station : '';

                            return (
                                <div key={index}>
                                    <div className="field is-horizontal mt-3">
                                        <div className="field-label is-normal">
                                            <label className="label">路線 {index + 1}:</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        value={line}
                                                        onMouseOver={() => handleMouseOver(stationKey)}
                                                        onMouseLeave={handleMouseLeave}
                                                        onChange={(e) => handleChange(e, stationKey, 'line')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="field is-horizontal">
                                        <div className="field-label is-normal">
                                            <label className="label">駅名 {index + 1}:</label>
                                        </div>
                                        <div className="field-body">
                                            <div className="field">
                                                <div className="control">
                                                    <input
                                                        className="input"
                                                        type="text"
                                                        value={stationName}
                                                        onMouseOver={() => handleMouseOver(stationKey)}
                                                        onMouseLeave={handleMouseLeave}
                                                        onChange={(e) => handleChange(e, stationKey, 'station')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            case 'image':
                return (
                    <div>
                        <div className="field">
                            <label className="label">バスタイプ:</label>
                            <div className="control">
                                <div className="select">
                                    <select
                                    value={editableData.bathtype}
                                    onMouseOver={() => handleMouseOver('bathtype')}
                                    onMouseLeave={handleMouseLeave}
                                    onChange={(e) => handleChange(e, 'bathtype')}
                                    >
                                        <option value="3_unit">3点ユニット</option>
                                        <option value="2_unit">2点ユニット</option>
                                        <option value="1_unit">独立洗面台</option>
                                        <option value="">不明</option>
                                    </select>
                                </div>
                            </div>
                            <label className="label">間取り図:</label>
                            <DetectedImageDisplay imageUrl={maisokuUrls.blueprint} />
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="modal is-active">
            <div className="modal-background" onClick={closeModal}></div>
            <div className="modal-card"  style={{ maxWidth: "100%", width: "100%" }}>
                <header className="modal-card-head">
                    <p className="modal-card-title">物件詳細</p>
                    <button className="delete" aria-label="close" onClick={closeModal}></button>
                </header>
                <section className="modal-card-body"  style={{ height: "80vh" }}>
                    <div className="columns">
                        <div className="column is-8">
                            <MaisokuDisplay imageUrl={maisokuUrls.main} boundingBox={boundingBox} />
                        </div>
                        <div className="column is-4" style={{ overflowY: "auto", maxHeight: "80vh" }}>
                            <div className="tabs">
                                <ul>
                                {tabs.map((tab) => (
                                    <li key={tab.id} className={activeTab === tab.id ? 'is-active' : ''}>
                                    <a onClick={() => setActiveTab(tab.id)}>{tab.name}</a>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            {renderTabContent()}
                            <button disabled={isUpdating} onClick={updateData} className="button is-primary mt-3">更新</button>
                            <button disabled={isUpdating} onClick={deleteData} className="button is-danger mt-3">削除</button>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    );
};

export default ModalDetails;
