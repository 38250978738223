import React, { useState, useEffect } from 'react';
import { Amplify, Auth, Storage, API } from 'aws-amplify';
import awsconfig from "../aws-exports";

const FileUploader = ({ triggerReRender }) => {
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false); // 追加
    const [uploadSuccess, setUploadSuccess] = useState(false); // 追加
    const [fileName, setFileName] = useState(""); // 追加

    const handleChange = async (e) => { // async を追加
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        setFileName(selectedFile.name); // 追加
    };

    useEffect(() => {
        if (file) {
            handleSubmit();
        }
    }, [file]);

    const executeAllAPI = async (filename) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const options = {
                body: {
                    "bucket": process.env.REACT_APP_INPUT_BUCKET_NAME,
                    "s3_key": filename,
                    "id_token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };

            await API.post('stg-bukken-scan-executor', '/', options);
            // console.log('API結果:', responseJson);
            // alert('処理を受け付けました！1分ほどしてから更新ボタンを押してください！');
            // triggerReRender();
        } catch (error) {
            console.error("Error:", error);
            alert('PDFの処理に失敗しました。');
        }
    }

    const handleSubmit = async () => {

        setUploading(true); // 追加
        setUploadSuccess(false); // 追加

        try {
            await Storage.put(file.name, file, {
                bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
                region: 'ap-northeast-1',
                level: "private",
                contentType: file.type,
            });
            setUploadSuccess(true); // 追加
            executeAllAPI(file.name)
        } catch (error) {
            console.error('アップロードエラー:', error);
            alert('アップロードに失敗しました。');
        } finally {
            setUploading(false); // ここに移動
        }
    };

    return (
        <div className="container">
            <form className="box">
                <div className="file has-name">
                    <label className="file-label">
                        <input
                            className="file-input"
                            type="file"
                            accept="application/pdf"
                            onChange={handleChange}
                        />
                        <span className="file-cta">
                            <span className="file-label">ファイルを選択</span>
                        </span>
                        <span className="file-name">{fileName}</span>
                    </label>
                </div>
                {uploadSuccess && (
                <p className="has-text-success">アップロードが成功しました。1分ほどしてから更新ボタンを押してください。</p>
                )}
            </form>
        </div>
    );
};

export default FileUploader;
