const ReinsHeaderDict = {
    "都道府県名": 'prefecture',
    "所在地名1": 'municipalities',
    "所在地名2": 'city',
    "所在地名3": 'street',
    "建物名": 'building_name',
    "沿線略称（1）": 'station_1_line',
    "駅名（1）": 'station_1_station',
    "徒歩（分）1（1）": 'station_1_walk',
    "現況": "residence_status",
    "取引態様": "transaction_mode",
    "賃料": "rental_price",
    "建物面積1": "total_footprint",
    "バルコニー（テラス）面積": "balcony_area",
    "都市計画": "urbanization_promotion_area",
    "管理費": "administrative_cost",
    "間取タイプ（1）": "room_layout",
    "間取部屋数（1）": "total_rooms",
    "報酬ヶ月": "advertisement_fee",
    "礼金2（ヶ月）": "gratuity_fee",
    "敷金2（ヶ月）": "security_deposit",
    "建物構造": "building_structure",
    "地上階層": "stories_high",
    "所在階": "floor",
    "築年月（西暦）": "built_year",
}

export default ReinsHeaderDict;