import React, { useState, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import ModalDetails from './ModalDetails'; // この行を追加

import ReinsHeaderList from '../constants/ReinsHeaderList';
import ReinsHeaderDict from '../constants/ReinsHeaderDict';
import TablePagination from '@mui/material/TablePagination';

const ReadDynamoDb = ({ triggerReRender }) => {
    const [data, setData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null); // この行を追加
    const [isModalOpen, setIsModalOpen] = useState(false); // この行を追加
    const [isDownloading, setIsDownloading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);

        triggerReRender();
    };

    // ヘッダを生成する関数
    const generateHeaders = (data) => {
        let headers = ["Index"];
        const sampleItem = data[0];
        function extractKeysFromItem(item, prefix = "") {
            for (let key in item) {
                if (typeof item[key] === 'object' && !Array.isArray(item[key]) && item[key] !== null) {
                    extractKeysFromItem(item[key], prefix + key + "_");
                } else {
                    // _posを含まないキーだけをヘッダに追加
                    if (!['top', 'bottom', 'left', 'right'].includes(key)) {
                        headers.push(prefix + key);
                    }
                }
            }
        }
        extractKeysFromItem(sampleItem);
        return headers;
    };


    // オブジェクトをフラットな形式に変換する関数
    const flattenObject = (obj, prefix = "") => {
        let flatItem = {};
        for (let k in obj) {
            if (typeof obj[k] === 'object' && !Array.isArray(obj[k]) && obj[k] !== null) {
                let flatSubItem = flattenObject(obj[k], prefix + k + "_");
                for (let subK in flatSubItem) {
                    flatItem[subK] = flatSubItem[subK];
                }
            } else {
                // _posを含まないキーだけをフラットアイテムに追加
                if (!['top', 'bottom', 'left', 'right'].includes(k)) {
                    flatItem[prefix + k] = obj[k];
                }
            }
        }
        return flatItem;
    };


    // CSVを生成する関数
    const convertToCSV = (data) => {
        const headers = generateHeaders(data);
        const reinsHeaders = ReinsHeaderList;
        let csvContent = reinsHeaders.join(",") + '\r\n';

        data.forEach((item, index) => {
            let flatItem = flattenObject(item);
            let row = [];

            reinsHeaders.slice(0).forEach(header => {
                if (Object.keys(ReinsHeaderDict).includes(header)) {
                    if (header === '築年月（西暦）') {
                        if (flatItem['built_year'] === undefined || flatItem['built_month'] === undefined) {
                            row.push("");
                        } else {
                            row.push(flatItem['built_year'] + flatItem['built_month']);
                        }
                    } else if (['報酬ヶ月', '礼金2（ヶ月）', '敷金2（ヶ月）'].includes(header)) {
                        let value = flatItem[ReinsHeaderDict[header]];
                        let numericValue = parseFloat(value);

                        if (!isNaN(numericValue)) {
                            // 数値に変換可能な場合
                            if (numericValue >= 0 && numericValue <= 10) {
                                // 0以上10以下の数値の場合
                                row.push(`${numericValue}ヶ月`);
                            } else if (numericValue >= 50) {
                                // 50以上の数値の場合
                                numericValue = numericValue / 100;
                                row.push(`${numericValue}ヶ月`);
                            } else {
                                row.push(value !== undefined ? value.replace(",", "") : "");
                            }
                        } else {
                            // 数値に変換できない場合
                            row.push(value !== undefined ? value.replace(",", "") : "");
                        }
                    }else {
                        row.push(flatItem[ReinsHeaderDict[header]] !== undefined ? flatItem[ReinsHeaderDict[header]].replace(",", "") : "");
                    }
                } else {
                    row.push("");
                }
            });
            csvContent += row.join(",") + '\r\n';
        });

        return csvContent;
    };




    const exportCSVFile = () => {
        setIsDownloading(true);
        const csv = convertToCSV(data);
        const blob = new Blob([csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', 'export.csv');
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        // 少し遅延させてからisDownloadingの状態を更新する
        setTimeout(() => {
            setIsDownloading(false);
        }, 100); // 100ミリ秒の遅延
    }


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const options = {
                body: {
                    "id_token": `${(await Auth.currentSession()).getIdToken().getJwtToken()}`
                },
            };

            const responseJson = await API.post('stg-bukken-scan-db-controller-read', '/', options);
            const sortedResponseJson = responseJson.sort(function(a, b) {
                return (a.created_at < b.created_at) ? 1 : -1;  //オブジェクトの昇順ソート
            });
            setData(sortedResponseJson);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const slicedData = data ? Object.keys(data).flatMap(user => data[user]).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];
    return (
        <div className="container">
            <button className="button is-primary" onClick={exportCSVFile} disabled={isDownloading}>Download CSV</button>
            <h2 className="title is-2 has-text-centered has-text-grey-dark">
                登録済み販売図面
            </h2>
            {slicedData.length > 0 ? (
                <table className="table is-fullwidth is-striped is-hoverable">
                    <thead className="has-background-grey-lighter">
                        <tr>
                            <th className="has-text-grey-dark">建物名</th>
                            <th className="has-text-grey-dark">住所</th>
                            <th className="has-text-grey-dark">地上階数</th>
                            <th className="has-text-grey-dark">建築年</th>
                            <th className="has-text-grey-dark">総戸数</th>
                            <th className="has-text-grey-dark">登録日</th>
                        </tr>
                    </thead>
                    <tbody>
                        {slicedData.map((item, index) => (
                            <tr key={index} className="has-text-grey" onClick={() => handleRowClick(item)}>
                                <td>{item.building_name}</td>
                                <td>{item.address}</td>
                                <td>{item.stories_high}</td>
                                <td>
                                    {item.built_year}年{item.built_month}月
                                </td>
                                <td>{item.total_units}</td>
                                <td>{item.created_at}</td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={2}>
                                <TablePagination
                                    count={data ? Object.keys(data).flatMap(user => data[user]).length : 0} // 合計行数
                                    sx={{ float: 'left' }}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            ) : (
                <p className="has-text-centered has-text-grey">No data found</p>
            )}
            {isModalOpen && <ModalDetails data={selectedItem} closeModal={handleCloseModal} triggerReRender={triggerReRender} />} {/* この行を追加 */}
        </div>
    );
};

export default ReadDynamoDb;
